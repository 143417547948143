import * as React from "react"

import { StaticImage } from "gatsby-plugin-image"
import Card from "../components/Card"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Form, Button, InputGroup, Breadcrumb, Table, Container, Row, Col } from '@themesberg/react-bootstrap';
import Grid from '../components/Grid'
import { navigate, Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faHome, faEnvelopeOpen, faSearch, faSignOutAlt, faUserShield, faAngleDown, faEye } from "@fortawesome/free-solid-svg-icons";
import Parse from '../vendor/Parse'
import { useLocation } from '@reach/router';
import NumberTable from '../components/NumberTable'
import { Modal } from '@themesberg/react-bootstrap';
import Logo from "../components/Logo";
const { useState, useEffect } = React

const IndexPage = () => {

  const [data, setData] = useState([])

  useEffect(() => {
    fetch('/homepage.json')
    .then(e => e.json())
    .then(res => {
      setData(res)
    })
  }, [])

  return <Layout>

    <Container className="text-center">

      <Logo />
    </Container>
    <Container>

      <Row>
        {
          data?.map(item => <Col lg={4}>


            <Card
              buttonText={"Watch"}
              cover={item?.snippet?.thumbnails?.medium?.url}
              title={""}
              //subtitle={item?.node?.snippet?.description}
              text={item?.snippet?.title}
              onButtonClick={() => { navigate(`/pl/${item?.snippet?.playlistId}`)}}
            /></Col>)
        }

      </Row>
    </Container>

  </Layout >
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
